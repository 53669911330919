import { HorizontalStack, Icon } from "@shopify/polaris";
import { ExternalSmallMinor, InfoMinor } from "@shopify/polaris-icons";
import {
  LearnMoreAboutLink,
  LearnMoreIconWrapper,
  LearnMoreLinkContainer,
} from "@vendor-app/app/AdminRoute/components/elements/styles";
import styled from "styled-components";

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
`;

export const HelpFooter = () => {
  return (
    <FooterWrapper>
      <HorizontalStack gap={"2"}>
        <Icon source={InfoMinor} color="highlight" />
        <LearnMoreLinkContainer>
          Still need help?&nbsp;
          <LearnMoreAboutLink
            // TODO: Update with new help doc link once provided
            href="https://help.smartrr.com/docs/getting-started/implementation/how-do-i-install-smartrr-on-a-shopify-2.0-theme-automatic-install"
            rel="noreferrer"
            target="_blank"
          >
            Read our help documentation.
            <LearnMoreIconWrapper>
              <Icon source={ExternalSmallMinor} color="base" />
            </LearnMoreIconWrapper>
          </LearnMoreAboutLink>
        </LearnMoreLinkContainer>
      </HorizontalStack>
    </FooterWrapper>
  );
};
