import { Button } from "@shopify/polaris";
import { ExternalMinor } from "@shopify/polaris-icons";
import { SetupStoreAccess } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/store";
import styled from "styled-components";

interface AddWidgetButtonProps {
  disabled: boolean;
  url: string;
}

const Wrapper = styled.span`
  & :hover {
    color: var(--p-color-text);
    text-decoration: none !important;
  }
`;

export const AddWidgetButton = ({ disabled, url }: AddWidgetButtonProps) => {
  const isLoading = SetupStoreAccess.useLoading();

  return (
    <Wrapper>
      <Button icon={ExternalMinor} disabled={isLoading || disabled} size="slim" external url={url}>
        Add widget
      </Button>
    </Wrapper>
  );
};
