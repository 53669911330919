import { Select, Text } from "@shopify/polaris";
import { SetupStoreAccess } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/store";
import { getThemeSelectOptions } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/utils/getThemeSelectOptions";
import { useCallback, useEffect, useMemo, useState } from "react";

interface ThemeSelectProps {
  onSelect(themeId: string): void;
  helpText?: string;
}

export const ThemeSelect = ({ onSelect, helpText }: ThemeSelectProps) => {
  const [selected, setSelected] = useState<string>();

  const themes = SetupStoreAccess.useThemes();
  const setup = SetupStoreAccess.useSetup();
  const isLoading = SetupStoreAccess.useLoading();

  const onChange = useCallback((themeId: string) => {
    onSelect(themeId);
    setSelected(themeId);
  }, []);

  const options = useMemo(
    () => (setup ? getThemeSelectOptions(setup.snippetsThemeId, themes) : []),
    [setup?.snippetsThemeId, themes]
  );

  useEffect(() => {
    if (themes.length && !selected) {
      onChange(String(options[0].value));
    }
  }, []);

  if (!themes.length || !setup) {
    return (
      <Text as="p" color="subdued">
        No themes found
      </Text>
    );
  }

  return (
    <Select
      label="Theme"
      value={selected}
      options={options}
      onChange={onChange}
      disabled={isLoading}
      helpText={helpText}
    />
  );
};
