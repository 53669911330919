import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { formatProductString } from "../formatProductString";
import { useMemo } from "react";

type ShopifyThemeCustomizationPreviewPaths = "/account" | "/products";

export const useThemeCustomizationUrl = (
  shopName: string | undefined,
  themeId: string | undefined,
  previewPath?: ShopifyThemeCustomizationPreviewPaths
) => {
  const { purchasables, isLoading: isPurchasablesLoading } = useSmartrrVendorSelector(
    state => state.purchasables
  );
  const formattedProductString = formatProductString(purchasables);
  return useMemo(() => {
    if (!shopName || !themeId || isPurchasablesLoading || !purchasables.length) {
      return "";
    }
    const queryParams = previewPath
      ? previewPath === "/products"
        ? `?previewPath=${previewPath}/${formattedProductString}`
        : `?previewPath=${previewPath}`
      : "";

    return `https://admin.shopify.com/store/${shopName}/themes/${themeId}/editor${queryParams}`;
  }, [purchasables.length, themeId]);
};
