import React from "react";
import { TabHeader } from "../../../libs/styles";
import { Text } from "@shopify/polaris";

export const OnSiteSetupPageHeader = () => {
  return (
    <TabHeader>
      <Text variant="headingLg" as="h2" fontWeight="semibold">
        On-site setup{" "}
      </Text>
      <Text variant="bodyMd" as="span" color="subdued">
        Use the steps below to install Smartrr onto your store&apos;s Shopify theme.{" "}
      </Text>
    </TabHeader>
  );
};
