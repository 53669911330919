import styled from "styled-components";

interface ISmartrrSnippetsCardProps {
  removePaddingTop: boolean;
}

export const SetupIconWrapper = styled.div`
  .Polaris-Icon--colorBase svg {
    fill: #92e6b5;
  }
  color: gray;
  margin-right: 15px;
`;

export const SubscriptionWidgetBanner = styled.div`
  .Polaris-Banner {
    .Polaris-Icon--colorHighlight svg {
      fill: #007f5f;
    }
    background-color: #f2f8f5;
  }
`;

export const SetupIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const DocIconWrapper = styled.div`
  .Polaris-Icon__Svg {
    fill: #2c6ecb;
  }
`;

export const SmartrrSnippetsCard = styled.div<ISmartrrSnippetsCardProps>`
  .Polaris-LegacyCard__Section {
    padding-top: ${props => props.removePaddingTop && 0};
  }
  margin: 15px 0 15px 0;
`;

export const ModalTitle = styled.span`
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28px;
`;

export const DisabledButton = styled.div`
  .Polaris-Button--disabled {
    background: #fafbfb;
    border: 1px solid #d2d5d8;
    color: #8c9196;
  }
`;
export const DocsLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DocsLink = styled.a`
  display: flex;
  flex-direction: row;
`;

export const DocsLinkContainer = styled.div`
  margin: 30px 0 30px 0;
`;

export const TabHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 5px;
`;
