import { ISmartrrShopTheme } from "@smartrr/shared/shopifyRest/theme";
import { toViewShortDate } from "@smartrr/shared/utils/renderViewDate";

export const getThemeSelectOptions = (setupSnippetsId: string, themes: ISmartrrShopTheme[] | undefined) => {
  return (themes ?? [])
    .sort((a, b) => {
      if (+setupSnippetsId === a.id) {
        return -1;
      } else if (+setupSnippetsId === b.id) {
        return 1;
      } else if (+new Date(a.updated_at) > +new Date(b.updated_at)) {
        return -1;
      }
      return 1;
    })
    .map(theme => {
      return {
        label: `${theme.name} | Updated: ${toViewShortDate(new Date(theme.updated_at))} ${
          theme.role === "main" ? "(Live theme)" : ""
        }`,
        value: String(theme.id),
      };
    });
};
