import { VerticalStack } from "@shopify/polaris";
import React from "react";
import { OnSiteSetupPageHeader } from "./components/OnSiteSetupPageHeader";
import { DuplicateThemeBanner } from "./components/DuplicateThemeBanner";
import { AddSubscriptionWidgetCard } from "./components/AddSubscriptionWidgetCard";
import { AddCustomerPortalWidgetCard } from "./components/AddCustomerPortalWidgetCard";
import { HelpFooter } from "./components/HelpFoooter";

export const OnSiteSetupNew = () => {
  return (
    <VerticalStack gap={"5"}>
      <OnSiteSetupPageHeader />
      <DuplicateThemeBanner />
      <AddSubscriptionWidgetCard />
      <AddCustomerPortalWidgetCard />
      <HelpFooter />
    </VerticalStack>
  );
};
