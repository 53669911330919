import { Banner } from "@shopify/polaris";
import { ShopLink } from "@vendor-app/app/_sharedComponents/ShopLink";
import styled from "styled-components";

const BlackShopLink = styled(ShopLink)`
  text-decoration: underline;
  color: var(--p-color-text) !important;
`;

export const ContactSupportBanner = ({ widgetType }: { widgetType: "customer portal" | "subscription" }) => {
  return (
    <span data-testId="contact-support-banner">
      <Banner status="warning">
        It looks like your theme doesn&apos;t support the block for our Smartrr {widgetType} widget. Please use
        another theme or contact <BlackShopLink to={"/admin/support"}>support</BlackShopLink>.
      </Banner>
    </span>
  );
};
