import { Card, Text, VerticalStack } from "@shopify/polaris";
import { AddWidgetButton } from "../AddWidgetButton";
import { ThemeSelect } from "../ThemeSelect";
import { useCallback, useMemo, useState } from "react";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { parseShopName } from "@smartrr/shared/utils/parseShopName";
import { useThemeCustomizationUrl } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/utils";
import { SetupStoreAccess } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/store";
import { ContactSupportBanner } from "../ContactSupportBanner";

export const AddCustomerPortalWidgetCard = () => {
  const [isThemeValid, setIsThemeValid] = useState<boolean>(true);
  const org = useActiveOrganizationSelector();
  const themes = SetupStoreAccess.useThemes();
  const { validateTheme } = SetupStoreAccess.useActions();
  const { shopName } = parseShopName(org?.myShopifyDomain);
  const [themeId, setThemeId] = useState<string>("");

  // Linking merchants to their shop's account page (assuming they're logged in)
  const themeCustomizeLink = useThemeCustomizationUrl(shopName, themeId, "/account");

  const disabled = useMemo(() => !isThemeValid || !themes.length, [isThemeValid, themes.length]);

  const onSelect = useCallback(async (id: string) => {
    const isValid = await validateTheme(id, "templates/customers/account.json");
    setIsThemeValid(isValid);
    setThemeId(id);
  }, []);
  return (
    <Card>
      <VerticalStack gap={"3"}>
        <Text as="h2" variant="headingMd">
          2. Add customer portal widget
        </Text>
        {!isThemeValid && <ContactSupportBanner widgetType="customer portal" />}
        <VerticalStack gap={"4"}>
          <Text as="p">
            Enables customers to manage their subscriptions using Smartrr&apos;s customer portal. Customize the
            portal via &quot;Theming&quot; from the left hand navigation.
          </Text>
          <ThemeSelect
            onSelect={onSelect}
            helpText="Make sure to log in and add the widget on the account page, not the log-in page"
          />
          <AddWidgetButton url={themeCustomizeLink} disabled={disabled} />
        </VerticalStack>
      </VerticalStack>
    </Card>
  );
};
