import React from "react";
import { Banner } from "@shopify/polaris";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { SetupStoreAccess } from "../../../../libs/store";

export const DuplicateThemeBanner = () => {
  const organization = useActiveOrganizationSelector();
  const { update } = SetupStoreAccess.useActions();
  const setup = SetupStoreAccess.useSetup();
  const shopUrl = organization?.myShopifyDomain;

  if (setup?.onSiteSetup.smartrrSnippets?.isBannerDismissed) {
    return <React.Fragment />;
  }

  return (
    <Banner
      title={"We recommend first duplicating your live theme."}
      action={{ content: "Go to Shopify", url: `https://${shopUrl}/admin/themes`, external: true }}
      status="info"
      onDismiss={() => update({ path: "onSiteSetup.smartrrSnippets.isBannerDismissed", val: true })}
    >
      <p>
        Installing on a copy of your theme will prevent Smartrr from showing up on your shop before you&apos;re
        ready. Name it something that is easily recognizable, such as: &#123;Theme name&#125; — Smartrr
        &#123;date&#125;.
      </p>
    </Banner>
  );
};
