import { ISetup } from "@smartrr/shared/entities/Organization";
import { ISmartrrShopTheme } from "@smartrr/shared/shopifyRest/theme";
import { captureException } from "@smartrr/shared/utils/captureException";
import { ensureShopifyGid, shopifyNumericIdFromGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { useEffect, useState } from "react";

import { loadSetup } from "@vendor-app/app/_state/actionCreators/organization";

import { useToast } from "../../../../../_sharedComponents/Toast/ToastProvider";
import { useSmartrrVendorDispatch } from "../../../../../_state/typedVendorReduxHooks";
import { useRestClient } from "../../../../components/auth/RestProviderWrapper";
import { loadThemes } from "../../utils/themes";

const useAdminSetupRouteRemoteData = (setup: ISetup) => {
  const restClient = useRestClient();
  const { addToast } = useToast();
  const dispatch = useSmartrrVendorDispatch();

  const [themes, setThemes] = useState<ISmartrrShopTheme[]>([]);
  const [isLoadingThemes, setIsLoadingThemes] = useState<boolean>(true);

  const [linkedProductIds, setLinkedProductIds] = useState<string[]>([]);
  const [linkedVariantIds, setLinkedVariantIds] = useState<string[]>([]);
  const [isProductsPageLoading, setIsProductsPageLoading] = useState(true);
  const [isVariantsPageLoading, setIsVariantsPageLoading] = useState(true);

  const loadProductsAndVariantsFromShopify = async (selectedSellingPlanId: string) => {
    setIsProductsPageLoading(true);
    setIsVariantsPageLoading(true);

    const { productIds, variantIds } = await loadSellingPlanGroupProductsAndVariants(selectedSellingPlanId);

    setLinkedProductIds(productIds);
    setLinkedVariantIds(variantIds);
    setIsProductsPageLoading(false);
    setIsVariantsPageLoading(false);
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const themesRes = await loadThemes(themes, restClient, addToast);
        setIsLoadingThemes(false);
        if (themesRes) {
          setThemes(themesRes);
        }
        await dispatch(loadSetup());
        if (setup.selectedSellingPlanId) {
          await loadProductsAndVariantsFromShopify(setup.selectedSellingPlanId);
        } else {
          setIsProductsPageLoading(false);
          setIsVariantsPageLoading(false);
        }
      } catch (error) {
        captureException("Error: Unable to load all Shopify data in AdminSetupRoute", error);
      }
    };
    void loadInitialData();
  }, []);

  return {
    loadProductsAndVariantsFromShopify,
    themes,
    isLoadingThemes,
    linkedProductIds,
    linkedVariantIds,
    isProductsPageLoading,
    isVariantsPageLoading,
    setIsProductsPageLoading,
    setIsVariantsPageLoading,
  };
};

export default useAdminSetupRouteRemoteData;

export async function loadSellingPlanGroupProductsAndVariants(sellingPlanGroupShopifyId: string): Promise<{
  productIds: string[];
  variantIds: string[];
}> {
  const result = await typedFrontendVendorApi.getReq("/selling-plan-groups/:id", {
    params: {
      id: shopifyNumericIdFromGid(sellingPlanGroupShopifyId),
    },
  });

  if (result.type !== "success") {
    throw new Error("Error looking up Selling Plan Group by Shopify Id");
  }

  return {
    productIds: result.body.productIds.map(p => ensureShopifyGid("Product", p)),
    variantIds: result.body.productVariantIds.map(p => ensureShopifyGid("ProductVariant", p)),
  };
}
