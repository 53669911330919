import { Req } from "@smartrr/shared/req";
import { getShopifyAsset } from "@smartrr/shared/shopifyRest/asset";
import { ISmartrrShopTheme, getShopifyThemes } from "@smartrr/shared/shopifyRest/theme";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

// Files are used when determining the validity of a theme - checking if file exists or not
export type ThemeValidationAssetFiles = "templates/customers/account.json" | "templates/product.json";

export async function getAsset(restClient: Req, themeId: number, assetId: string) {
  const res = await getShopifyAsset(themeId, assetId, restClient);
  if (res.type === "error" || res.body.asset.value === undefined) {
    const error = `Couldn't get asset ${assetId}`;
    throw new Error(error);
  }
  return res.body.asset.value;
}

export async function updateAsset(themeId: number, assetId: string, value: string) {
  return typedFrontendVendorApi.putReq("/theme/:themeId/asset", {
    params: {
      themeId: `${themeId}`,
    },
    reqBody: {
      key: assetId,
      value,
    },
  });
}

export async function updateCustomerPortalToken(themeId: number) {
  return typedFrontendVendorApi.putReq("/theme/:themeId/portal-token", {
    params: {
      themeId: `${themeId}`,
    },
  });
}

/**
 * @deprecated Passing restClient down isn't ideal or necessary. Remove when `updatedSetupSteps` / `updated-setup-steps` is fully rolled out
 * use {@link fetchShopifyThemes} instead
 */
export const loadThemes = async (
  themes: ISmartrrShopTheme[],
  restClient: Req,
  toast: (content: string, error?: boolean | undefined) => void
) => {
  if (themes.length) {
    return;
  }

  const res = await getShopifyThemes(restClient);
  if (res.type === "success") {
    if (res.body.themes.length) {
      return [...res.body.themes].sort((a, b) => {
        if (a.role === "main") {
          return -1;
        } else if (+new Date(a.updated_at) > +new Date(b.updated_at)) {
          return -1;
        }
        return 1;
      });
    }
    toast("No themes found. Try to add theme first");
  } else {
    toast("Error getting themes. Try to reload the page");
  }
};

export const fetchShopifyThemes = async (): Promise<ISmartrrShopTheme[]> => {
  const res = await typedFrontendVendorApi.getReq("/integrations/shopify/rest/themes");

  if (res.type !== "success" || !res.body.themes.length) {
    throw new Error("Error retrieving shops's themes");
  }

  // Sorting themes with main theme first, then by updated_at descending
  return [...res.body.themes].sort((a, b) => {
    if (a.role === "main") {
      return -1;
    }
    if (b.role === "main") {
      return 1;
    }
    return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
  });
};

export const validateShopifyTheme = async (themeId: string | number, file: ThemeValidationAssetFiles) => {
  const res = await typedFrontendVendorApi.getReq(`/integrations/shopify/rest/themes/${themeId}/assets`, {
    query: { "asset[key]": file },
  });

  return res.type === "success";
};
