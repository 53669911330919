const SHOP_NAME_SLUG = /^[\w-]+$/i;
export function parseShopName(shopNameOrUrl: string | undefined = "") {
  let shopUrl = shopNameOrUrl ?? "";
  if (SHOP_NAME_SLUG.test(shopNameOrUrl)) {
    shopUrl = `${shopNameOrUrl}.myshopify.com`;
  }

  const shopName = shopUrl.replace(".myshopify.com", "");

  return {
    shopName,
    shopUrl,
  };
}
