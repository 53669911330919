import { useApolloClient } from "@apollo/client";
import { adminConfigRoutePrefix } from "@smartrr/shared/constants";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { updateSetup } from "@vendor-app/app/_state/actionCreators/organization";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { demoSellingPlanGroup } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/constants/sellingPlanInput";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { Dispatch, SetStateAction, useState } from "react";

import { ISetupFieldsForUpdate } from "../../../../../libs/types";

// Note: lumping the hooks together just under remote data is not a great practice
// for the sake of getting some organization this is helpful but this should be
// divided into individual concerns
const useSubscriptionSetupRemoteData = (setModalOpen: Dispatch<SetStateAction<boolean>>) => {
  const apolloClient = useApolloClient();
  const { setup } = useSmartrrVendorSelector(state => state.vendorOrganizations);
  const { addToast } = useToast();
  const dispatch = useSmartrrVendorDispatch();

  const [isDemoSubscriptionProgramLoading, setIsDemoSubscriptionProgramLoading] = useState(false);

  const handleCreatingNewSubscriptionProgram = () => {
    navigateWithShopInQuery(
      `${adminConfigRoutePrefix}/plans/create`,
      undefined,
      {
        replace: true,
        hash: "fromSetup",
      },
      false
    );

    setModalOpen(false);
  };

  const handleCreatingDemoSubscriptionProgram = async () => {
    setIsDemoSubscriptionProgramLoading(true);

    try {
      const selectedSellingPlanId = await smartrrCreateDemoSellingPlanGroup();

      if (!selectedSellingPlanId) {
        addToast("Something went wrong. Please try again later.");
        return;
      }

      handleUpdateSetup({
        selectedSellingPlanId,
        subscriptionSetup: {
          sellingPlan: {
            date: ISODateString.toString(new Date()),
            completed: true,
            demo: true,
          },
        },
        ...setup.subscriptionSetup?.product,
      });

      addToast("Program created");
    } finally {
      setIsDemoSubscriptionProgramLoading(false);
    }
  };

  // This might be un necessary as we can just call "updateSetup" where needed
  const handleUpdateSetup = (setupField: ISetupFieldsForUpdate) => {
    dispatch(updateSetup(setupField));
  };
  return {
    isDemoSubscriptionProgramLoading,
    handleCreatingNewSubscriptionProgram,
    handleCreatingDemoSubscriptionProgram,
  };
};

export default useSubscriptionSetupRemoteData;

async function smartrrCreateDemoSellingPlanGroup(): Promise<string | undefined> {
  const result = await typedFrontendVendorApi.postReq("/selling-plan-groups", {
    reqBody: demoSellingPlanGroup,
  });

  if (result.type === "success") {
    return result.body.shopifyId ?? undefined;
  }
}
